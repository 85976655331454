.footer{
  position: relative;
  margin-top: 25px;
  padding: 50px 100px;
  background-color: black;
  color: white;
}
.footerContainer{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;
}
.footerColumn{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.columnTitle{
  font-size: x-large;
  font-weight: bold;
  color: rgb(15, 210, 210);
}
.columnText{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.link{
  text-decoration: none;
}
.columnTextItem a{
  color: white;

  .copywrightText{
    margin: auto;
  }

  .link{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    // font-weight: bold;
    background-color: transparent;
    outline: none;
    user-select: none;
    height: 80px;
    border: none;
    transition: color 0.2s ease-in-out;
  }
}

.newsletterEmailInput{
  height: 25px;
  padding: 5px;
  outline: none;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.newsletterEmailSubmit{
  height: 35px;
  padding: 5px;
  outline: none;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.newsletterEmailSubmit:hover{
  cursor: pointer;
}
