.ourGamesSection{
  position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    gap: 1.5rem;
    // height: 90vh;
  }
  .sectionTitle{
    text-align: center;
    font-weight: bolder;
    font-size: 60px;
    color: rgb(15, 210, 210);
  }
  .reasonCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    .card{
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 275px;
      height: max-content;
      padding: 10px;
      border-radius: 15px;
      gap: 0.5rem;
      box-shadow: 0px 4px 4px 4px rgba(127, 127, 127, 0.5);
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 0.4s ease,
                  transform 0.4s ease;
      .cardTitle{
        color: rgb(127, 127, 127);
        font-size: 30px;
        font-weight: bold;
      }
      .cardDesc{
        color: rgb(127, 127, 127);
      }
    }
    :nth-child(1){
      transition-delay: 0s;
    }
    :nth-child(2){
      transition-delay: 0.25s;
    }
    :nth-child(3){
      transition-delay: 0.5s;
    }
    :nth-child(4){
      transition-delay: 0.75s;
    }
  }
  .fadeIn{
    .card{
      opacity: 1;
      transform: translateY(0px);
    }
    :nth-child(1){
      transition-delay: 0s;
    }
    :nth-child(2){
      transition-delay: 0.25s;
    }
    :nth-child(3){
      transition-delay: 0.5s;
    }
    :nth-child(4){
      transition-delay: 0.75s;
    }
  }
  
  //MEDIA QUERIES
  @media screen and (max-width: 630px) {
    .ourGamesSection{
      margin-top: 30px;
      padding: 15px;
    }
    .sectionTitle{
      font-size: 55px;
    }
  }


  //new changes
  .cardSymbol{
    height: 100%;
    display: grid;
    row-gap: 10px;
    border-radius: 10px;
    align-items: center;
    background: var(--cardBG);
    box-shadow: 0 2px 4px rgba(140,140,141,0.549);
    overflow: hidden;
    &:hover{
        background: var(--cardHover);
        box-shadow: 1 4px 4px rgba(85,85,114,0.549);
        transition: .3s ease;
    }

    .imageDiv{
        height: 180px;
        width: 100%;
        overflow: hidden;

        img{
            height: 100%;
            width: 100%;
            object-fit: fill;
            transition: 2ss ease;
        }

        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
  }

.moreBtn{
  width: 100%;
  padding-bottom: 4rem;

  .moreText{
    position: absolute;
    right: 0;
    padding-right: 10rem;
    font-weight:600;
    font-size: 20px;
    color: rgb(46, 42, 42);
  }
}

@media screen and (max-width: 900px){
  .moreText{
    padding-right: 5rem;
  }
}
