.policyContainer {
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 4rem;
}
.policyContainer .secDiv {
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
}
.policyContainer .secDiv .sectionContent {
  text-align: left;
  font-size: 18px;
  color: rgb(126, 126, 126);
  line-height: 1.5;
  letter-spacing: 1px;
}
.policyContainer .secDiv .sectionContent span {
  font-weight: bold;
}
.policyContainer .secDiv .sectionListItem {
  text-align: left;
  font-size: 16px;
  color: rgb(126, 126, 126);
  line-height: 1.5;
  letter-spacing: 1px;
  padding-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .policyContainer {
    padding: 1rem 2rem;
  }
  .policyContainer .secDiv {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .policyContainer .secDiv .sectionTitle {
    font-size: 30px;
  }
  .policyContainer .secDiv .sectionContent {
    text-align: left;
    font-size: 16px;
    color: rgb(126, 126, 126);
    line-height: 1.5;
    letter-spacing: 1px;
  }
  .policyContainer .secDiv .sectionContent span {
    font-weight: bold;
  }
  .policyContainer .secDiv .sectionListItem {
    text-align: left;
    font-size: 16px;
    color: rgb(126, 126, 126);
    line-height: 1.5;
    letter-spacing: 1px;
    padding-bottom: 10px;
  }
}/*# sourceMappingURL=PrivacyPolicyPage.css.map */