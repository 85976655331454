.policyContainer{
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: white;
    // padding: 3rem 4rem;
    padding-top: 4rem;

    .secDiv{
        margin-top: 2rem;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;

        .sectionContent{
            text-align: left;
            font-size: 18px;
            color: rgb(126, 126, 126);
            line-height: 1.5;
            letter-spacing: 1px;

            span{
                font-weight: bold;
            }
        }

        .sectionListItem{
            text-align: left;
            font-size: 16px;
            color: rgb(126, 126, 126);
            line-height: 1.5;
            letter-spacing: 1px;
            padding-bottom: 10px;
        }
    }
}

@media screen and (max-width: 500px){
    .policyContainer{
        padding: 1rem 2rem;
    
        .secDiv{
            margin-top: 1rem;
            margin-bottom: 1rem;
    
            .sectionTitle{
                font-size: 30px;
            }
            .sectionContent{
                text-align: left;
                font-size: 16px;
                color: rgb(126, 126, 126);
                line-height: 1.5;
                letter-spacing: 1px;
    
                span{
                    font-weight: bold;
                }
            }
    
            .sectionListItem{
                text-align: left;
                font-size: 16px;
                color: rgb(126, 126, 126);
                line-height: 1.5;
                letter-spacing: 1px;
                padding-bottom: 10px;
            }
        }
    }
}