.aboutSection{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.aboutImage{
  max-width: 100%;
  height: auto;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.6s ease,
              transform 0.6s ease;
}
.fadeIn {
  opacity: 1;
  transform: translateY(0px);
}

.aboutRight{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.header{
  max-width: 600px;
  font-weight: bolder;
  font-size: 60px;
  color: rgb(15, 210, 210);
}
.body{
  width: 450px;
  font-size: 20px;
  color: rgb(127, 127, 127);
}
.button button{
  width: 125px;
  height: 45px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: medium;
  border: 1px solid rgb(15, 210, 210);
  border-radius: 50px;
  color: rgb(127, 127, 127);
  background-color: white;
  box-shadow: 0px;
  transition: color 0.2s ease-in-out, 
              background-color 0.2s ease-in-out,
              box-shadow 0.2s ease-in-out;
}
.button button:hover, .button button:focus{
  color: white;
  background-color: rgb(15, 210, 210);
  box-shadow: 0px 0px 12px 4px rgb(154, 239, 239);
}

//MEDIA QUERIES
@media screen and (max-width: 1200px) {
  .aboutSection{
    flex-direction: column-reverse;
  }
  .aboutRight{
    display: flex;
    align-items: center;
    text-align: center;
  }
  .body{
    width: 80%;
  }
}
@media screen and (max-width: 630px) {
  .aboutSection{
    padding: 15px;
  }
}