.contactUsPage{
    margin-top: 3rem;
    padding: 1rem;
    align-items: center;
    text-align: center;

    .formTitle{
        font-size: 2rem;
        color: rgb(24, 24, 24);
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;
        // box-shadow: 1px 1px 3px 2px #ddd;
    }

    .formSection{
        .contactForm{
            background: #fff;
            padding: 20px;
            display: block;
            margin: 0 auto;

            input,textarea{
                display: flex;
                flex-direction: column;
                width: 90%;
                margin: 10px auto;
                padding: 8px;
                background: #f1f1f1;
                border: 1px solid #ddd;
            }

            .formBtn{
                height: 30px;
                width: 70px;
                align-items: center;
                border: none;
                background-color: rgb(24, 198, 218);
                color: #fff;
            }


        }
    }

    .infoSection{
        width: 80%;
        margin: 0 auto;
        line-height: 1;

        p{
            font-size: 12px;
            letter-spacing: 1px;
        }

        .mail{
            color: rgb(30, 175, 191);
            font-weight: bold;
        }
    }
}

@media screen and (min-width: 500px){
    .contactUsPage{
        padding: 1rem 2rem;
    }
}

@media screen and (min-width: 650px){
    .contactUsPage{
        padding: 1rem 4rem;
    }
}

@media screen and (min-width: 840px){
    .contactUsPage{
        padding: 1rem 6rem;
    }
}

@media screen and (min-width: 1040px){
    .contactUsPage{
        margin: 5rem;
        padding: 2rem 10rem;
    }
}

@media screen and (min-width: 1240px){
    .contactUsPage{
        padding: 2rem 16rem;
    }
}

@media screen and (min-width: 1440px){
    .contactUsPage{
        padding: 2rem 20rem;
    }
}

@media screen and (min-width: 1640px){
    .contactUsPage{
        padding: 2rem 40rem;
    }
}